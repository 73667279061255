import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDateObject',
})
export class ToDateObjectPipe implements PipeTransform {

  transform(value: string | Date): Date {
    if (value instanceof Date) {
      return value;
    }

    if (value) {
      return value.length === 10 ? new Date(value + ' 00:00') : new Date(value);
    }
  }

}
