import { NgModule } from '@angular/core';
import { BaseSharedModule } from '../../../../_base-shared/base-shared.module';
import { ServerErrorsComponent } from './components/server-errors/server-errors.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports:      [
    BaseSharedModule,
    NgxMatSelectSearchModule,
    MatTabsModule,
    MatExpansionModule,
    MatStepperModule
  ],
  declarations: [
    ServerErrorsComponent,
  ],
  exports:      [
    BaseSharedModule,
    ServerErrorsComponent,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule
  ],
})
export class SharedModule {
}
