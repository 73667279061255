import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector:    'app-spinner-btn',
  templateUrl: './spinner-btn.component.html',
  styles:      [],
})
export class SpinnerBtnComponent implements OnInit {
  @Input() public disabled                     = false;
  @Input() public loading: boolean | number    = false;
  @Input() public type: 'button' | 'submit'    = 'submit';
  @Input() public loadingType: 'text' | 'icon' = 'text';
  @Input() public name                         = 'Submit';
  @Input() public color: ThemePalette          = 'primary';
  @Input() public class: string;
  @Input() public blueButtonBackground = false;
  constructor() {
  }

  ngOnInit(): void {
  }

}
