import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../_base-shared/contracts/laravel-response.interface';
import { PaymentActionResponse } from '../../../_base-shared/contracts/payment-action-response.interface';
import { Case } from '../../../_base-shared/models/Case/Case';
import { UserCaseDataValidation } from '../../../_base-shared/models/Case/UserCaseDataValidation';
import {
  UserCaseRelation,
  UserCaseRelationValidation,
} from '../../../_base-shared/models/Case/UserCaseRelationValidation';
import { PaymentCard } from '../../../_base-shared/models/Payment/PaymentCard';
import { PaymentRequest } from '../../../_base-shared/models/Payment/PaymentRequest';
import { PaymentTerm } from '../../../_base-shared/models/Payment/PaymentTerm';
import { OppwaOrder } from '../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import { User } from '../../../_base-shared/models/User/User';
import { ClientBaseApiService } from './_shared/services/client-base-api.service';

interface NewCardRequest {
  payment_request_uuid?: string;
  amount: number;
  card_number: string;
  cvv: string;
  holder: string;
  expiry_month: string;
  expiry_year: string;
  brand: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientAppService extends ClientBaseApiService {
  private navSubjectSource = new BehaviorSubject<boolean>(false);
  public navSubject$       = this.navSubjectSource.asObservable();

  public setNavSubject(isTrue: boolean) {
    this.navSubjectSource.next(isTrue);
  }

  public paymentDetails(data) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.uuid }/payment`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentRequest(paymentRequestUuid: string, signature: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest(
      {signature, with: relations}, 'get',
    );
    return this.http.get<LaravelResourceResponse<PaymentRequest>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public authorizePaymentRequest(paymentRequestUuid: string, data: {
    id_card: string,
    signature: string
  }): Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    return this.http.post<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid + '/authorize', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getLatestUserCase(userUuid: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/client/users/' + userUuid + '/latest-case',
      {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getUserByIdCard(idCard: number): Observable<LaravelResourceResponse<User>> {
    return this.http.post<LaravelResourceResponse<User>>(this.apiUrl + '/auth/get-user-by-dni', {id_card: idCard})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getCaseByIdCard(id_card: any, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({...id_card, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/public/users' + '/get-case-by-dni', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPaymentCards(userUuid: string, caseUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-cards',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentCardsPublic(caseUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public deletePaymentCardPublic(caseUuid: string, cardUuid: string) {
    return this.http.delete<LaravelResourceResponse<null>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards/' + cardUuid,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public setDefaultPaymentCard(caseUuid: string, cardUuid: string) {
    return this.http.post<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards/' + cardUuid + '/set-default', {}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public chargeExistingCard(userUuid: string, caseUuid: string,
                            data: { payment_request_uuid?: string, card_id: number, amount: number }) {
    return throwError('Deprecated')
  }

  public chargeNewCard(userUuid: string, caseUuid: string, data: NewCardRequest) {
    return throwError('Deprecated')
  }

  public paymentAmount(userUuid: string, caseUuid: string) {
    return this.http.get<LaravelResourceResponse<{
      plan_type: 'debt_plan' | 'additional_plans',
      installment: PaymentTerm
    }>>(this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-amount')
      .pipe(catchError(response => this.handleError(response)));
  }

  checkPaymentStatus(caseUuid: string, data) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseUuid + '/payment', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getClientDocuments(caseId) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/documents`)
      .pipe(catchError(response => this.handleError(response)));
  }

  optOut(data) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/client/unsubscribe`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  optOutPublic(data) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/public/unsubscribe', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO: move to public service
  notaryDownload(data): Observable<HttpResponse<Blob>> {
    return this.http.get(`${ this.apiUrl }/public/notary-download/get-files`, {
      params: data, observe: 'response', responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }

  showUser(userUuid: string) {
    return this.http.get<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateUser(userUuid: string, userData) {
    return this.http.put<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid, userData)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCases(userUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/client/users/' + userUuid + '/cases')
      .pipe(catchError(response => this.handleError(response)));
  }

  showUserCase(userUuid: string, caseUuid: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  showCase(caseUuid: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/client/cases/' + caseUuid, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCase(caseUuid: string, caseData) {
    return this.http.put<LaravelResourceResponse<Case>>(this.apiUrl + '/client/cases/' + caseUuid, caseData)
      .pipe(catchError(response => this.handleError(response)));
  }

  getCaseDepartmentUser(caseUuid: string, agentRole: string) {
    return this.http.get<LaravelResourceResponse<User>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/department-agent', {params: {department_type: agentRole}},
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  signValidation(caseUuid: string, userUuid: string, data) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/signature', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFiles(uuId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFile(caseUuid: string, documentId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseUuid }/documents-upload/${ documentId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseRelation(caseUuid: string, userUUid: string, relation: UserCaseRelation | 'payment_plans.type') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relations?relation=' + relation)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseDataValidation(caseUuid: string, userUUid: string) {
    return this.http.get<LaravelResourceResponse<UserCaseDataValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/data-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseRelationValidations(caseUuid: string, userUUid: string, relation: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relation-validations?relation=' + relation)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseRelationValidation(caseUuid: string, userUUid: string, data: object) {
    return this.http.put<LaravelResourceResponse<UserCaseRelationValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relation-validations', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseDataValidation(caseUuid: string, userUuid: string, data: object) {
    return this.http.put<LaravelResourceResponse<UserCaseRelationValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/data-validations', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseIncomeValidation(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/income-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseExpenseValidation(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/expense-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  previewContract(caseUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/contract-preview',
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewMandate(caseUuid: string, clientRole: 'client' | 'partner') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/mandate-preview?signedBy' + clientRole,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewRightOfAccess(caseUuid: string, clientRole: 'client' | 'partner') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/right-of-access-preview?client_role=' + clientRole,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewAssetCreditorList(caseUuid: string, type: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/creditor-and-asset-list-preview?type=' + type,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewDebtCancellationRequest(caseUuid: string, signed: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/debt-cancellation-request-preview?signed_by=' + signed,
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveDebtCancellationRequestSignature(caseId: number, data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/debt-cancellation-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  previewProposal(caseUuid: string, signed: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/proposal-preview?signed_by=' + signed,
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveProposalSignature(caseId: number, data) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/proposal-v2', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public makePayment(userUuid: string, caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse<PaymentActionResponse>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payments-v2', data
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  public checkTransactionStatus(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<OppwaOrder>>(
      this.apiUrl + '/client/transaction-status', data
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  previewDebtExoneration(caseUuid: string, signed: string): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/debt-exoneration-preview?signed_by=' + signed,{}
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveDebtExonerationSignature(caseId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/public/cases/${ caseId }/debt-exoneration-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
