<div class="mt-3 mb-3">
  <!-- Local Alerts -->
  <mat-card *ngIf="localAlerts && localAlerts.length" class="mb-2">
    <mat-action-list align="start" class="d-flex justify-content-end">
      <button mat-icon-button (click)="closeLocalResponse()"><mat-icon>close</mat-icon></button>
    </mat-action-list>
    <mat-card-content>
      <mat-list class="mt-3">
        <mat-list-item *ngFor="let alert of localAlerts">
          <app-alert [message]="alert.message" [status]="alert.status" [icon]="alert.icon"></app-alert>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <!-- Response Errors -->
  <mat-card *ngIf="response && response?.message && response.code === 422">
    <mat-action-list align="start" class="d-flex justify-content-end">
      <button mat-icon-button (click)="closeResponse()"><mat-icon>close</mat-icon></button>
    </mat-action-list>
    <mat-card-header>
      <mat-card-title>{{ response.message }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="response.errors" class="alert alert-danger">
        <div *ngFor="let errorList of response.errors | keyvalue" class="mb-2">
          <h4 class="m-0 p-0">{{ errorList.key | dotCaseToSnake | snakeCaseToCamel }}</h4>
          <mat-list class="m-0 p-0">
            <mat-list-item *ngFor="let error of errorList.value">
              <span>{{ error | dotCaseToSnake | snakeCaseToCamel }}</span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
