import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector:      'app-html-shadow-dom',
  templateUrl:   './html-shadow-dom.component.html',
  styleUrls:     ['./html-shadow-dom.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class HtmlShadowDomComponent implements OnInit {
  @Input() unsafeHtml: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
