<nav *ngIf="showNav" class="navbar navbar-expand-lg navigation-bar navbar-dark"
     [class.client-nav]="!authUser || authUser?.role?.slug === 'user'"
     [class.desktop]="isDesktop" [class.mobile]="!isDesktop">
  <div *ngIf="logoLoaded && !publicRouteLogoVisible"
       [ngClass]="{'d-flex flex-row w-100 justify-content-between align-items-end': !authUser || ( authUser?.role?.slug === 'user') && !isDesktop}">
    <div class="navbar-brand" style="width: 125px" *ngIf="!navV2 || isDesktop">
      <a [href]="logoDestination">
        <img *ngIf="!authUser || authUser.role?.slug === 'user'" class="img-fluid"
             src="assets/img/logos/unified-logo.png" alt="Unifye Logo"
             style="width: 125px;margin-left:161px;margin-top: 1.5rem;"
             [ngStyle]="{'margin-left': isDesktop ? '159px' : '-5px'}">
      </a>
    </div>
<!--    <div *ngIf="authUser?.role?.slug === 'user' && !isDesktop && showWhatsAppButton">
      <a type="button" class="btn btn-link mr-2"
         style="color:#2764AE;font-size: 18px;border:1px solid #2764AE;padding: 0.6rem 1.125rem;"
         href="https://wa.me/34960136099"><i
        class="far fa-comment-dots" style="color:#2764AE"></i>&nbsp;&nbsp;&nbsp;Whatsapp</a>
    </div>-->
  </div>
  <div *ngIf="logoLoaded && publicRouteLogoVisible" class="w-100 d-flex" [ngClass]="isDesktop?'justify-content-center' : 'justify-content-left'">
    <div [ngStyle]="{'width': isDesktop ? '80%' : '68%' }"
         [ngClass]="{'d-flex flex-row w-100 justify-content-start align-items-center': (!this.authUser || ( this.authUser?.role?.slug === 'user') && !this.isDesktop) && !this.publicRouteLogoVisible}">
      <div class="row">
        <div class="col-8 d-flex flex-column justify-content-center align-items-center">
          <div [ngStyle]="{'width': isDesktop ? '690px' : '' }">
            <a class="navbar-brand" *ngIf="!navV2 || isDesktop">
              <a [href]="logoDestination">
                <ng-container *ngIf="!authUser || (authUser && authUser.role?.slug === 'user')">
                  <img class="img-fluid"
                       src="assets/img/logos/unified-logo.png" alt="Unifye Logo"
                       style="width: 95px;margin-top: 1.5rem;">
                </ng-container>
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="collapse navbar-collapse client-nav-desktop" id="navbarNavDropdown"
       style="position: absolute; right: 100px;top: 37px;">
    <!-- Desktop Nav -->
    <ul *ngIf="isDesktop && authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'user'"
        class="navbar-nav mr-auto">
      <div style="width: 38rem;" class="d-flex flex-row justify-content-around">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon style="color:#242E61">home</mat-icon>
          <span class="client-nav-label" routerLink="/" routerLinkActive="route-active"
                [routerLinkActiveOptions]="{ exact: true }">Inicio</span>
        </div>
        <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon style="color:#242E61">card_travel</mat-icon>
          <span class="client-nav-label"
                [routerLink]="['/onboarding', 'cases', selectedCase.uuid, 'users', authUser?.uuid, 'your-case']"
                routerLinkActive="route-active">Tu caso</span>
        </div>
        <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon style="color:#242E61">description</mat-icon>
          <span class="client-nav-label"
                [routerLink]="['/documents', 'cases', selectedCase.uuid,'users', authUser?.uuid,'document-upload']"
                routerLinkActive="route-active">Docs</span>
        </div>
        <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon style="color:#242E61">payment</mat-icon>
          <span class="client-nav-label" [routerLink]="['/payments', 'cases', selectedCase.uuid, 'payment-handler']"
                routerLinkActive="route-active">Pagos</span>
        </div>
        <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon style="color:#242E61">person</mat-icon>
          <span class="client-nav-label" [routerLink]="['/profile', 'cases', selectedCase.uuid, 'profile']"
                routerLinkActive="route-active">Perfil</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center" (click)="logOut()">
          <mat-icon style="color:#242E61">logout</mat-icon>
          <span class="client-nav-label" routerLinkActive="route-active">Salir</span>
        </div>
      </div>
    </ul>
  </div>
  <!--Mobile nav-->
  <mat-toolbar *ngIf="!isDesktop && authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'user'"
               class="mobile-nav nav-d-block">
    <div class="d-flex flex-column align-items-center justify-content-center" routerLink="/">
      <mat-icon>home</mat-icon>
      <a routerLinkActive="route-active" (click)="changeNav()"
         [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
    </div>
    <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
         [routerLink]="['/onboarding/cases', selectedCase.uuid, 'users', authUser.uuid, 'your-case']">
      <mat-icon>card_travel</mat-icon>
      <a
        routerLinkActive="route-active">Tu caso</a>
    </div>
    <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
         [routerLink]="['/documents/cases', selectedCase.uuid,'users', authUser.uuid,'document-upload']">
      <mat-icon>description</mat-icon>
      <a
        routerLinkActive="route-active">Docs</a>
    </div>
    <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
         [routerLink]="['/payments/cases', selectedCase.uuid, 'payment-handler']">
      <mat-icon>payment</mat-icon>
      <a routerLinkActive="route-active">Pagos</a>
    </div>
    <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
         [routerLink]="['/profile/cases', selectedCase.uuid, 'profile']">
      <mat-icon>person</mat-icon>
      <a routerLinkActive="route-active">Perfil</a>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" (click)="logOut()">
      <mat-icon>logout</mat-icon>
      <a routerLinkActive="route-active">Salir</a>
    </div>
  </mat-toolbar>
</nav>
<!--Nav for client verify-->
<div class="dashboard-client d-flex flex-row justify-content-start align-items-center ml-2 flex-row mt-3"
     *ngIf="navV2 && !isDesktop">
  <a (click)="changeNav()" class="d-flex flex-row justify-content-start align-items-center"
     routerLink="/client/dashboard" style="color:#2764AE; font-size: 18px; font-family: 'Inter'">
    <mat-icon>keyboard_arrow_left</mat-icon>
    {{ 'SHARED.home' | translate }}
  </a>
  <img class="img-fluid" src="assets/img/logos/unified-logo.png" alt="Unifye Logo"
       style="width: 94px;margin-left: 18%">
</div>
