import { Component, Input, OnInit } from '@angular/core';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';

@Component({
  selector:    'app-server-errors',
  templateUrl: './server-errors.component.html',
  styleUrls:   [],
})
export class ServerErrorsComponent implements OnInit {

  @Input() public errorResponse: LaravelResourceResponse;
  @Input() public fieldName: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
