<ng-template [ngIf]="wrapperControl?.errors && ((wrapperControl.touched || wrapperControl.dirty) || submitted)">
  <!-- Required -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('required')">
    <span *ngIf="requiredError">{{ requiredError }}</span>
    <span *ngIf="!requiredError" [innerHTML]="'VALIDATORS.required' | translate:{input: label}"></span>
  </mat-error>
  <!-- Email -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('email')">
    <span *ngIf="emailError">{{ emailError }}</span>
    <span *ngIf="!emailError" [innerHTML]="'VALIDATORS.email' | translate:{input: label}"></span>
  </mat-error>
  <!-- Pattern -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('pattern')">
    <span *ngIf="patternError">{{ patternError }}</span>
    <span *ngIf="!patternError" [innerHTML]="'VALIDATORS.pattern' | translate:{input: label}"></span>
  </mat-error>
  <!-- Min -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('min')">
    <span *ngIf="minError">{{ minError }}</span>
    <span *ngIf="!minLengthError" [innerHTML]="'VALIDATORS.min' | translate:
    {input: label, required: wrapperControl.errors.min?.min}">
    </span>
  </mat-error>
  <!-- Max -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('max')">
    <span *ngIf="maxError">{{ maxError }}</span>
    <span *ngIf="!maxError" [innerHTML]="'VALIDATORS.max' | translate:
    {input: label, required: wrapperControl.errors.max?.max}">
    </span>
  </mat-error>
  <!-- Min Length -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('minlength')">
    <span *ngIf="minLengthError">{{ minLengthError }}</span>
    <span *ngIf="!minLengthError" [innerHTML]="'VALIDATORS.minlength' | translate:
    {input: label, requiredLength: wrapperControl.errors.minlength?.requiredLength}">
    </span>
  </mat-error>
  <!-- Max Length -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('maxlength')">
    <span *ngIf="maxLengthError">{{ maxLengthError }}</span>
    <span *ngIf="!maxLengthError" [innerHTML]="'VALIDATORS.maxlength' | translate:
    {input: label, requiredLength: wrapperControl.errors.maxlength?.requiredLength}">
    </span>
  </mat-error>
  <!-- Unique -->
  <mat-error class="custom-error" [hidden]="!wrapperControl.hasError('unique')">
    <span *ngIf="uniqueError">{{ uniqueError }}</span>
    <span *ngIf="!uniqueError" [innerHTML]="'VALIDATORS.unique' | translate:
    {input: label, takenBy: wrapperControl.errors.unique !== true ? wrapperControl.errors.unique : ''}">
    </span>
  </mat-error>
</ng-template>
