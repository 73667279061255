import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector:    'app-input-validation-errors',
  templateUrl: './input-validation-errors.component.html',
  styles:      [],
})
export class InputValidationErrorsComponent implements OnInit {
  @Input() wrapperControl: NgControl;
  @Input() submitted: boolean;
  @Input() label: string;
  @Input() requiredError: string;
  @Input() emailError: string;
  @Input() patternError: string;
  @Input() minError: string;
  @Input() maxError: string;
  @Input() minLengthError: string;
  @Input() maxLengthError: string;
  @Input() uniqueError: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
