<div class="row">
  <div *ngIf="isLoading" class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
  <div *ngIf="!isLoading" class="col-12">
    <div class="card">
      <div class="card-body">
        <div *ngIf="isSuccessful">
          <h1 class="text-center">{{ 'SHARED-COMPONENTS.payment_outcome.success.heading' | translate }}</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary">check_circle</mat-icon>
          </p>
        </div>
        <div *ngIf="!isSuccessful">
          <h1 class="text-center">{{ 'SHARED-COMPONENTS.payment_outcome.error.heading' | translate }}</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
          </p>
        </div>
        <div *ngIf="message">
          <p class="text-center">{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
