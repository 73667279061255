<div [class]="'d-flex justify-content-center align-items-center spinner-button ' + class"
     style="min-width: 100px; min-height: 37px">
  <button
    [ngStyle]="{'background-color': blueButtonBackground ? '#242e61' : '','color': blueButtonBackground ? 'white' : '',
    'padding': blueButtonBackground ? '8px' : '', 'font-size': blueButtonBackground ? '16px' : ''}"
    mat-raised-button [color]="color" *ngIf="!loading" class="w-100" [type]="type" [disabled]="disabled">
    {{ name }}
  </button>
  <ng-container *ngIf="loading">
    <div *ngIf="loadingType === 'text'" class="spinner-border text-primary">
      <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="loadingType === 'icon'" class="d-flex justify-content-center align-items-center"
         style="min-width: 67px">
      <mat-spinner diameter="25" [color]="color"></mat-spinner>
    </div>
  </ng-container>
</div>
