import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'snakeCaseToCamel',
})
export class SnakeCaseToCamelPipe implements PipeTransform {
  transform(value, args?: string) {
    if (value) {
      value = value.replace(/_/g, ' ').replace(/-/g, ' ');
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return value;
    }
  }
}
