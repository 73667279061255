import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'dotCaseToSnake',
})
export class DotCaseToSnakePipe implements PipeTransform {
  transform(value, args?: string) {
    return value ? value.replace(/\./g, '_') : value;
  }
}
