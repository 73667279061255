import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { ScriptLoaderService } from '../../../../../_base-shared/services/script-loader.service';
import { environment } from '../../../environments/environment';
import { ClientGlobalEventService } from '../../_shared/services/client-global-event.service';
import { ClientAppService } from '../../client-app.service';
import { ClientAuthService } from '../../client-auth/client-auth.service';

@Component({
  selector:    'app-nav',
  templateUrl: './nav.component.html',
  styleUrls:   ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() selectedCase: Case;
  public activeBrand: 'unifye';
  public authUser: User;
  public showNav                = true;
  public navV2                  = false;
  public logoLoaded             = false;
  public logoDestination: string = environment.APP_ENV === 'local' ? environment.APP_URL : 'https://www.unifye.es/';
  public isDesktop: boolean;
  public showWhatsAppButton     = false;
  public publicRouteLogoVisible = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private breakpointObserver: BreakpointObserver,
              @Inject(DOCUMENT) private document: Document,
              private globalEventsService: ClientGlobalEventService,
              private scriptLoaderService: ScriptLoaderService,
              private clientAppService: ClientAppService,
              private clientAuthService: ClientAuthService,
  ) {
  }

  ngOnInit(): void {
    if (this.router.url !== '/') {
      this.publicRouteLogoVisible = this.router.url.includes('public');
      this.logoLoaded             = true;
    }
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.publicRouteLogoVisible = this.router.url.includes('public');
        this.logoLoaded             = true;
      }
    });
    this.globalEventsService.navStatus$.subscribe(result => this.showNav = result);
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.isDesktop = result.matches;
    });
    this.clientAppService.navSubject$.subscribe(isNavV2 => this.navV2 = isNavV2);
    this.showWhatsAppButton = true;
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.showWhatsAppButton = event.url === '/';
    });
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

  public changeNav(): void {
    this.clientAppService.setNavSubject(false);
    this.clientAppService.navSubject$.subscribe(data => {
      if (!data) {
        this.navV2 = data;
      }
    });
  }

  public logOut(): void {
    this.clientAuthService.logout().subscribe(res => {
      this.globalEventsService.setAuthUser(null);
      this.router.navigateByUrl('/auth/login');
    });
  }
}
