import { AbstractControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as cardValidation from 'creditcards';

export class CustomValidators {
  static idCardPattern(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue  = control.value?.toLowerCase();
      const regexpPattern = '^[\\dxyz]{1,1}\\d{7,7}[a-zA-Z]{1,1}$';
      const regexp        = new RegExp(regexpPattern);
      if (!regexp.test(controlValue)) {
        return {pattern: {requiredPattern: regexpPattern, actualValue: control.value}};
      }

      let prefix = controlValue.slice(0, 1).toLowerCase();
      if (prefix === 'x') {
        prefix = 0;
      } else if (prefix === 'y') {
        prefix = 1;
      } else if (prefix === 'z') {
        prefix = 2;
      }

      const idNumber    = (prefix * 10000000) + (+controlValue.slice(1, 8));
      const cardLetter  = controlValue.slice(8, 9).toLowerCase();
      const letterIndex = idNumber % 23;
      // const alphabet    = [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
      const alphabet    = [
        't',
        'r',
        'w',
        'a',
        'g',
        'm',
        'y',
        'f',
        'p',
        'd',
        'x',
        'b',
        'n',
        'j',
        'z',
        's',
        'q',
        'v',
        'h',
        'l',
        'c',
        'k',
        'e',
      ];

      if (cardLetter !== alphabet[letterIndex]) {
        return {pattern: {requiredValue: alphabet[letterIndex], actualValue: cardLetter}};
      }

      return null;
    };
  }

  static cardValidator(control: AbstractControl): ValidationErrors | null {
    const parseValue = cardValidation.card.parse(control.value);
    if (!cardValidation.card.isValid(parseValue)) {
      return {cardInvalid: true};
    }
    return null;
  }

  static cvcValidator(control: AbstractControl): ValidationErrors | null {
    if (!cardValidation.cvc.isValid(control.value)) {
      return {cvcInvalid: true};
    }
    return null;
  }

  static expiryMonthValidator(control: AbstractControl): ValidationErrors | null {
    const parseValue = cardValidation.expiration.month.parse(control.value);
    if (!cardValidation.expiration.month.isValid(parseValue)) {
      return {expiryMonthInvalid: true};
    }
    return null;
  }

  static expiryYearValidator(control: AbstractControl): ValidationErrors | null {
    const parseValue = cardValidation.expiration.year.parse(control.value);
    if (!cardValidation.expiration.year.isValid(parseValue)) {
      return {expiryYearInvalid: true};
    }
    return null;
  }
}

export const expiryDateInPast: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  // console.log(month, year);
  const month      = control.get('expiry_month');
  const year       = control.get('expiry_year');
  const monthParse = cardValidation.expiration.month.parse(month.value);
  const yearParse  = cardValidation.expiration.year.parse(year.value, true);
  if (month && year && cardValidation.expiration.isPast(monthParse, yearParse)) {
    return {expiryDateInPast: true};
  }
  return null;
};

export function mustMatch(controlName: string, matchingControlName: string): ValidatorFn {

  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const control         = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      return {mustMatch: true};
    }
    return null;
  };
}

export function mustNotMatchEmail(controlName1: string, controlName2: string, formGroup1: FormGroup, formGroup2: FormGroup): ValidatorFn {
  return (): ValidationErrors | null => {
    const control1 = formGroup1.get(controlName1);
    const control2 = formGroup2.get(controlName2);
    if (control1.value === control2.value) {
      return { mustNotMatch: true };
    }

    return null;
  };
}
