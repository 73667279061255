import { Component, Input, OnInit } from '@angular/core';
import { AlertStatus } from '../../contracts/app-alert.interface';

@Component({
  selector:    'app-alert',
  templateUrl: './alert.component.html',
  styles:      [],
})
export class AlertComponent implements OnInit {
  @Input() message: string;
  @Input() status: AlertStatus = 'primary';
  @Input() icon: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
