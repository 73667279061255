import { Component, Input, OnInit } from '@angular/core';
import { AppAlert } from '../../contracts/app-alert.interface';
import { LaravelResourceResponse } from '../../contracts/laravel-response.interface';

@Component({
  selector:    'app-server-response',
  templateUrl: './server-response.component.html',
})
export class ServerResponseComponent implements OnInit {
  @Input() localAlerts: Array<AppAlert> = [];
  @Input() response: LaravelResourceResponse;

  ngOnInit() {
  }

  public closeLocalResponse(): void {
    this.localAlerts = [];
  }

  public closeResponse(): void {
    this.response = null;
  }
}
