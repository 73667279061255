import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'app-empty-dataset',
  templateUrl: './empty-dataset.component.html',
  styles:      [],
})
export class EmptyDatasetComponent implements OnInit {
  @Input() show: boolean;
  @Input() setName: string;
  @Input() message: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
