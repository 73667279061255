import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector:    'app-payment-outcome',
  templateUrl: './payment-outcome.component.html',
  styles:      []
})
export class PaymentOutcomeComponent implements OnInit {
  public isLoading = 0;
  public isResolved: boolean;
  public isSuccessful: boolean;
  public message: string;

  constructor(protected route: ActivatedRoute,
              protected translate: TranslateService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.isResolved   = data.result === 'success' || data.result === 'error';
      this.isSuccessful = data.result === 'success';

      if (this.isResolved) {
        this.message = this.isSuccessful ?
          this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.message') :
          this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.message');
      } else {
        const transactionId = this.route.snapshot.queryParamMap.get('id');
        const resourcePath  = this.route.snapshot.queryParamMap.get('resourcePath');
        if (transactionId && resourcePath) {
          this.checkPaymentStatus(transactionId, resourcePath);
        } else {
          console.error('Unresolved payment with no resource path');
          this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.message');
        }
      }
    });
  }

  protected checkPaymentStatus(transactionId: string, resourcePath: string) {
    // implement
  }
}
