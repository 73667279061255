import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
export abstract class SharedXsrfInterceptorService implements HttpInterceptor {
  protected apiDomain: string;

  protected constructor(@Inject(PLATFORM_ID) protected platformId) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches       = (req.url).match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    const requestDomain = (matches && matches[1]) ? matches[1] : null;  // requestDomain will be null if no match is found

    if (isPlatformBrowser(this.platformId)) {
      if ( ! requestDomain || requestDomain !== this.apiDomain) {
        console.log('request is for: ' + requestDomain + ' | not for: ' + this.apiDomain + ' skipping header intercept');
        return next.handle(req);
      } else {
        // console.log('request is for ' + this.apiDomain + ' intercepting headers');
      }
    }

    const copiedReq = req.clone({
      withCredentials: true,
      headers:         req.headers.set('X-XSRF-TOKEN', decodeURIComponent(this.getCookie('XSRF-TOKEN'))),
    });

    return next.handle(copiedReq);
  }

  private getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number     = ca.length;
    const cookieName        = `${ name }=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return '';
  }

}
